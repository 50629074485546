<template>
  <div>
    <custom-loader v-if="loading" />
    <router-view />
  </div>
</template>

<script>
import Loading from "@/mixins/loading";
import { mapGetters } from "vuex";

export default {
  name: "App",
  mixins: [Loading],
  metaInfo() {
    return {
      title: this.getBrowserTitle,
      meta: [
        {
          name: "description",
          content: this.$t("description"),
        },
        {
          name: "theme-color",
          content: "#0e72c9",
        },
      ],
    };
  },
  data() {
    return {
      lastTouchEnd: 0,
    };
  },
  created() {
    document.addEventListener("touchstart", this.onTouchStart, false);
    document.addEventListener("touchend", this.onTouchEnd, false);
  },
  beforeDestroy() {
    document.removeEventListener("touchstart", this.onTouchStart, false);
    document.removeEventListener("touchend", this.onTouchEnd, false);
  },
  computed: {
    ...mapGetters(["ccName", "checkInName"]),
    getBrowserTitle() {
      const arr = [];
      if (this.ccName) arr.push(this.ccName);
      if (this.checkInName) arr.push(this.checkInName);
      return arr.length > 0 ? arr.join(" - ") : "";
    },
  },
  methods: {
    onTouchStart(event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    },
    onTouchEnd(event) {
      const now = new Date().getTime();
      if (now - this.lastTouchEnd <= 300) {
        event.preventDefault();
      }
      this.lastTouchEnd = now;
    },
  },
};
</script>
