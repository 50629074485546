import Vue from "vue";
import VueRouter from "vue-router";
import { getAuthorizationValue } from "@/utils/session/SessionUtil";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/components/ContentWrapper"),
    children: [
      {
        path: "/sign-in",
        name: "SignIn",
        component: () => import("@/views/SignIn"),
        beforeEnter: (to, from, next) => {
          if (!to.query.enc || !to.query.redirect) {
            alert("유효하지 않은 URL 주소입니다.");
          } else {
            next();
          }
        },
      },
      {
        path: "/certification",
        name: "Certification",
        component: () => import("@/views/Certification"),
        beforeEnter: (to, from, next) => {
          if (!to.query.enc || !to.query.redirect || !to.query.phone) {
            alert("유효하지 않은 URL 주소입니다.");
          } else {
            next();
          }
        },
      },
      {
        path: "/reservations/:reservationId",
        name: "ReservationStatus",
        component: () => import("@/views/reservations/ReservationStatus"),
        props: { useClass: "check_bg" },
      },
      {
        path: "/reservations/:reservationId/check-in-status",
        name: "CheckInStatus",
        component: () => import("@/views/reservations/CheckInStatus"),
      },
      {
        path: "/reservations/:reservationId/check-in",
        name: "CheckIn",
        component: () => import("@/views/reservations/CheckIn"),
      },
      {
        path: "/guides/how-to-use",
        name: "HowToUse",
        component: () => import("@/views/guides/HowToUse"),
      },
      {
        path: "/guides/setting-gps",
        name: "SettingGps",
        component: () => import("@/views/guides/SettingGps"),
      },
      {
        path: "/check-out/pay",
        name: "Pay",
        component: () => import("@/views/check-out/Pay"),
      },
      {
        path: "/check-out/pay-list",
        name: "PayList",
        component: () => import("@/views/check-out/PayList"),
      },
      {
        path: "/check-out/pay-my",
        name: "PayMy",
        component: () => import("@/views/check-out/PayMy"),
      },
      {
        path: "/check-out/pay-ok",
        name: "PayOk",
        component: () => import("@/views/check-out/PayOk"),
      },
      {
        path: "/check-out/pay-remainder",
        name: "PayRemainder",
        component: () => import("@/views/check-out/PayRemainder"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  Vue.prototype.$EventBus.$emit("loaderOff");
  Vue.prototype.$EventBus.$emit("closeConfirm");
  if (
    to.name === "SignIn" ||
    to.name === "Certification" ||
    to.name === "HowToUse"
  ) {
    next();
  } else {
    if (!getAuthorizationValue()) {
      next({ name: "SignIn" });
    } else {
      next();
    }
  }
});

export default router;
