import ProjectAPI from "@/api/ProjectAPI";
import { saveSession } from "@/utils/session/SessionUtil";
import {
  commonCodesSetCommonCodes,
  setCCName,
  setCheckInName,
  setCheckInDistance,
} from "@/utils/CommonCodeUtil";

class ProjectService {
  #api;

  constructor(api) {
    this.#api = api;
  }

  async fetchEnvironments() {
    const {
      data: {
        commonComCodes,
        businessComCodes,
        tsBizMst,
        noneChkinDstnc,
        noneChkinName,
      },
    } = await this.#api.getEnvironments();

    // 공통코드 localstorage에 저장
    commonCodesSetCommonCodes(commonComCodes, businessComCodes);
    setCCName(tsBizMst?.ccName);
    setCheckInName(noneChkinName);
    setCheckInDistance(noneChkinDstnc);
  }
  async signIn(
    { enc, phoneNumber, certificationCode, crtfcId },
    isCertification = false
  ) {
    const {
      data: { accessToken },
    } = isCertification
      ? await this.#api.postAuthIssueEcoFrontCertification(
          enc,
          certificationCode,
          crtfcId
        )
      : await this.#api.postAuthIssueEcoFront(enc, phoneNumber);
    saveSession(accessToken);
  }
  async fetchReservation(reservationId) {
    const { data } = await this.#api.getReservation(reservationId);
    return data;
  }
  async addReservationCompanion(reservationId, cmpnName, contactTel, sexCode) {
    const { status } = await this.#api.postReservationCompanion(
      reservationId,
      cmpnName,
      contactTel,
      sexCode
    );
    return status;
  }
  async resendReservaionSMS(reservationId, sno, contactTel) {
    const { status } = await this.#api.postReservationSMS(
      reservationId,
      sno,
      contactTel
    );
    return status;
  }
  async deleteReservationCompanion(reservationId, sno) {
    const { status } = await this.#api.deleteReservationCompanion(
      reservationId,
      sno
    );
    return status;
  }
  async getEcoFrontCheckInValidation(reservationId, longitude, latitude) {
    const { status } = await this.#api.getEcoFrontCheckInValidation(
      reservationId,
      longitude,
      latitude
    );
    return status;
  }
  async postEcoFrontSendCertification(enc, phone) {
    return await this.#api.postEcoFrontSendCertification(enc, phone);
  }
  async postEcoFrontResendCertification(enc, beforeCrtfcId) {
    return await this.#api.postEcoFrontResendCertification(enc, beforeCrtfcId);
  }
  async postUserCheckIn(isLocker, reservationId, longitude, latitude) {
    return await this.#api.postUserCheckIn(
      isLocker,
      reservationId,
      longitude,
      latitude
    );
  }
  async fetchUserPaymentHistoryPreUsed() {
    const { data } = await this.#api.getUserPaymentHistoryPreUsed();
    return data;
  }
  async fetchUserPaymentHistoryPaid() {
    const { data } = await this.#api.getUserPaymentHistoryPaid();
    return data;
  }
}

export default new ProjectService(ProjectAPI);
