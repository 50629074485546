import axios from "axios";
import { v4 as createUUID } from "uuid";
import store from "@/store";
import i18n from "@/i18n";
import { getDistanceLabel } from "@/utils/distance";

import {
  getAuthorizationValue,
  getEncAndRedirectAndPhoneNumber,
} from "@/utils/session/SessionUtil";
import { compactObjectValues } from "@/utils/ObjectUtil";

import {
  postAuthIssueEcoFront,
  postAuthIssueEcoFrontCertification,
} from "@/api/v1/auth/Auth";
import {
  getEcoFrontCheckInValidation,
  postEcoFrontResendCertification,
  postEcoFrontSendCertification,
} from "@/api/v1/ecofront/EcoFront";
import { postUserCheckIn } from "@/api/v1/user/User";
import {
  getReservation,
  postReservationCompanion,
  postReservationSMS,
  deleteReservationCompanion,
} from "@/api/v1/reservation/Reservation";
import {
  getUserPaymentHistoryPreUsed,
  getUserPaymentHistoryPaid,
} from "@/api/v1/payment/UserPaymentHistory";
import ProjectAPIBadRequestError from "@/api/error/ProjectAPIBadRequestError";
import ProjectAPIUnauthorizedError from "@/api/error/ProjectAPIUnauthorizedError";
import ProjectAPIForbiddenError from "@/api/error/ProjectAPIForbiddenError";
import ProjectAPINotFoundError from "@/api/error/ProjectAPINotFoundError";
import ProjectAPIInternalServerError from "@/api/error/ProjectAPIInternalServerError";

import NetworkError from "@/error/NetworkError";
import { getEnvironments } from "@/api/v1/env/Environment";
import { getDeviceInfos } from "@/utils/DeviceUtil";

export function getProjectAPIHeaders() {
  const headers = {};
  headers["transaction-id"] = createUUID();

  const authorizationValue = getAuthorizationValue();
  if (authorizationValue) {
    headers["Authorization"] = authorizationValue;
  }

  // device 정보 헤더 설정
  const { os, browser, userAgent } = getDeviceInfos();
  headers["device-os"] = os;
  headers["device-browser"] = browser;
  headers["device-user-agent"] = userAgent;
  return headers;
}

const confirmMessage = (key, values = []) => {
  store?._vm?.$EventBus.$emit("confirm", {
    title: i18n.t("common.alert"),
    content: i18n.t(`api.message.${key}`, [
      store?.getters?.checkInName,
      ...values,
    ]),
  });
};

const errorMessage = (key, values = []) => {
  store?._vm?.$EventBus.$emit("confirm", {
    title: i18n.t("common.alert"),
    content: i18n.t(`api.errorMessage.${key}`, [
      store?.getters?.checkInName,
      ...values,
    ]),
  });
};

class ProjectAPI {
  constructor() {
    const http = axios.create({
      baseURL: `${process.env.VUE_APP_API_HOST}`,
      timeout: Number(process.env.VUE_APP_API_TIMEOUT),
    });
    http.interceptors.request.use(
      async (config) => {
        config.headers = {
          ...config.headers,
          ...getProjectAPIHeaders(),
        };
        switch (config.method) {
          case "get":
            if (config.params) {
              config.params = compactObjectValues(
                JSON.parse(JSON.stringify(config.params)),
                true,
                false
              );
            }
            break;
          case "post":
          case "put":
          case "patch":
          case "delete":
            if (config.data) {
              config.data = compactObjectValues(
                JSON.parse(JSON.stringify(config.data)),
                true,
                true
              );
            }
            break;
        }
        store?._vm?.$EventBus.$emit("loaderOn");
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        store?._vm?.$EventBus.$emit("loaderOff");
        const { data, headers } = response;
        // logger.info("ProjectAPIResponse", {
        //   status: data?.status,
        //   headers,
        //   url: `${response.config.baseURL}${response.config.url}`,
        //   method: response.config.method,
        // });

        // user session is expired
        if (headers["user-session-expired"] === "true") {
          store?._vm?.$EventBus.$emit("user-session-expired");
        }
        if (data.status !== "OK") confirmMessage(data.status);
        return data;
      },
      (error) => {
        store?._vm?.$EventBus.$emit("loaderOff");
        // network error - timeout 또는 서버가 내려간 경우 등
        if (
          !error.response ||
          error.response.status === 408 ||
          error.code === "ECONNABORTED"
        ) {
          return Promise.reject(new NetworkError(error));
        }
        const { status, headers, data } = error.response;
        switch (status) {
          case 401:
            // TODO : 세션 만료 임시 조치
            // eslint-disable-next-line no-case-declarations
            const { enc, redirect } = getEncAndRedirectAndPhoneNumber();
            location.href = `/sign-in?enc=${enc}&redirect=${redirect}`;
            break;
          case 403:
            if (data.status === "REQUIRED_BOOKER_CERTIFICATE") {
              const { enc, redirect, phoneNumber } =
                getEncAndRedirectAndPhoneNumber();
              location.href = `/certification?enc=${enc}&redirect=${redirect}&phone=${phoneNumber}`;
            }
            break;
        }
        if (status !== 401) {
          switch (data.status) {
            case "OK":
            case "REQUIRED_BOOKER_CERTIFICATE":
              break;
            case "OUT_OF_GPS_DISTANCE":
              errorMessage(data.status, [
                getDistanceLabel(store?.getters?.checkInDistance),
              ]);
              break;
            default:
              errorMessage(status === 500 ? "SERVER_ERROR" : data.status);
              break;
          }
        }
        // logger.error(
        //   "ProjectAPIError",
        //   `url: ${config.baseURL}${config.url}, method: ${
        //     config.method
        //   }, http status:${status}, session expired: ${
        //     headers["user-session-expired"] === "true"
        //   }, ProjectAPIError: ${data.status}:${data.message}`,
        //   error
        // );
        // user session is expired
        if (headers["user-session-expired"] === "true") {
          store?._vm?.$EventBus.$emit("user-session-expired");
        }
        switch (status) {
          case 400:
            return Promise.reject(new ProjectAPIBadRequestError(data));
          case 401:
            return Promise.reject(new ProjectAPIUnauthorizedError(data));
          case 403:
            return Promise.reject(new ProjectAPIForbiddenError(data));
          case 404:
            return Promise.reject(new ProjectAPINotFoundError(data));
          case 500:
            return Promise.reject(new ProjectAPIInternalServerError(data));
        }
        return Promise.reject(error);
      }
    );

    this.http = http;
  }

  async getEnvironments() {
    return await getEnvironments.apply(this);
  }

  // Auth
  async postAuthIssueEcoFront(enc, phone) {
    return await postAuthIssueEcoFront.apply(this, [enc, phone]);
  }
  async postAuthIssueEcoFrontCertification(enc, code, crtfcId) {
    return await postAuthIssueEcoFrontCertification.apply(this, [
      enc,
      code,
      crtfcId,
    ]);
  }
  // Reservaion
  async getReservation(reservationId) {
    return await getReservation.apply(this, [reservationId]);
  }
  async postReservationCompanion(reservationId, cmpnName, contactTel, sexCode) {
    return await postReservationCompanion.apply(this, [
      reservationId,
      cmpnName,
      contactTel,
      sexCode,
    ]);
  }
  async postReservationSMS(reservationId, sno, contactTel) {
    return await postReservationSMS.apply(this, [
      reservationId,
      sno,
      contactTel,
    ]);
  }
  async deleteReservationCompanion(reservationId, sno) {
    return await deleteReservationCompanion.apply(this, [reservationId, sno]);
  }
  // EcoFront
  async getEcoFrontCheckInValidation(reservationId, longitude, latitude) {
    return await getEcoFrontCheckInValidation.apply(this, [
      reservationId,
      longitude,
      latitude,
    ]);
  }
  async postEcoFrontSendCertification(enc, phone) {
    return await postEcoFrontSendCertification.apply(this, [enc, phone]);
  }
  async postEcoFrontResendCertification(enc, beforeCrtfcId) {
    return await postEcoFrontResendCertification.apply(this, [
      enc,
      beforeCrtfcId,
    ]);
  }
  // User
  async postUserCheckIn(isLocker, reservationId, longitude, latitude) {
    return await postUserCheckIn.apply(this, [
      isLocker,
      reservationId,
      longitude,
      latitude,
    ]);
  }
  // User Payment History
  async getUserPaymentHistoryPreUsed() {
    return await getUserPaymentHistoryPreUsed.apply(this);
  }
  async getUserPaymentHistoryPaid() {
    return await getUserPaymentHistoryPaid.apply(this);
  }
}

export default new ProjectAPI();
