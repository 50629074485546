export const PATH_RESERVAION = "/reservations";

export function getReservation(reservationId) {
  return this.http.get(`${PATH_RESERVAION}/${reservationId}`);
}

export function postReservationCompanion(
  reservationId,
  cmpnName,
  contactTel,
  sexCode
) {
  return this.http.post(`${PATH_RESERVAION}/${reservationId}/companions`, {
    cmpnName,
    contactTel,
    sexCode,
  });
}

export function postReservationSMS(reservationId, sno, contactTel) {
  return this.http.post(`${PATH_RESERVAION}/${reservationId}/sms`, {
    sno,
    contactTel,
  });
}

export function deleteReservationCompanion(reservationId, sno) {
  return this.http.delete(
    `${PATH_RESERVAION}/${reservationId}/companions/${sno}`
  );
}
