import Vue from "vue";
import VueMeta from "vue-meta";
import router from "./router";
import App from "./App.vue";
import store from "./store";
import i18n from "./i18n";
import * as dayjs from "dayjs";
import "dayjs/locale/ko";

dayjs.locale("ko");

// CSS
import "@/assets/css/font.css";
import "@/assets/css/mobile.css";
import "@/assets/css/mobile_check.css";

Vue.use(VueMeta);
Vue.config.productionTip = false;
Vue.prototype.$EventBus = new Vue();

// load comcode first
store.dispatch("loadCommonCodes");

new Vue({
  router,
  store,
  i18n,
  dayjs,
  render: (h) => h(App),
}).$mount("#app");
