export const PATH_AUTH = "/auth";

export function postAuthIssueEcoFront(enc, phone) {
  return this.http.post(`${PATH_AUTH}/issue-ecofront`, {
    enc,
    phone,
  });
}

export function postAuthIssueEcoFrontCertification(enc, code, crtfcId) {
  return this.http.post(`${PATH_AUTH}/issue-ecofront/certification`, {
    enc,
    certificationNumber: code,
    crtfcId,
  });
}
