export default class NetworkError extends Error {
  #error;

  constructor(error) {
    super(error.message);
    this.#error = error;
  }

  get error() {
    return this.#error;
  }
}
