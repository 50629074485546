import Vue from "vue";
import Vuex, { mapActions } from "vuex";

import modal from "@/store/modules/modal";

import {
  getCommonCodesMap,
  getCCName,
  getCheckInName,
  getCheckInDistance,
} from "@/utils/CommonCodeUtil";
import { removeSession } from "@/utils/session/SessionUtil";
import ProjectService from "@/service/ProjectService";

Vue.use(Vuex);

Vue.mixin({
  methods: {
    ...mapActions("modal", ["confirm", "closeConfirm"]),
  },
});

export default new Vuex.Store({
  state: {
    commonComCodesMap: null,
    businessComCodesMap: null,
    ccName: null,
    checkInName: null,
    checkInDistance: null,
    payerName: null,
    goCheckIn: false,
  },
  actions: {
    async loadApplication({ dispatch }) {
      await ProjectService.fetchEnvironments();

      // 공통코드 loading - session storage -> vuex
      dispatch("loadCommonCodes");
    },
    async loadCommonCodes({ commit }) {
      const { commonComCodesMap, businessComCodesMap } = getCommonCodesMap();
      const ccName = getCCName();
      const checkInName = getCheckInName();
      const checkInDistance = getCheckInDistance();

      commit("SET_COMMON_COM_CODES_MAP", commonComCodesMap);
      commit("SET_BUSINESS_COM_CODES_MAP", businessComCodesMap);
      commit("SET_CC_NAME", ccName);
      commit("SET_CHECK_IN_NAME", checkInName);
      commit("SET_CHECK_IN_DISTANCE", checkInDistance);
    },
    async setPayerName({ commit }, payerName = null) {
      commit("SET_PAYER_NAME", payerName);
    },
    async setGoCheckIn({ commit }, goCheckIn = false) {
      commit("SET_GO_CHECK_IN", goCheckIn);
    },
    async clearUserSession() {
      removeSession();
    },
  },
  mutations: {
    SET_COMMON_COM_CODES_MAP(state, commonComCodesMap) {
      state.commonComCodesMap = commonComCodesMap;
    },
    SET_BUSINESS_COM_CODES_MAP(state, businessComCodesMap) {
      state.businessComCodesMap = businessComCodesMap;
    },
    SET_CC_NAME(state, ccName) {
      state.ccName = ccName;
    },
    SET_CHECK_IN_NAME(state, checkInName) {
      state.checkInName = checkInName;
    },
    SET_CHECK_IN_DISTANCE(state, checkInDistance) {
      state.checkInDistance = checkInDistance;
    },
    SET_PAYER_NAME(state, payerName) {
      state.payerName = payerName;
    },
    SET_GO_CHECK_IN(state, goCheckIn) {
      state.goCheckIn = goCheckIn;
    },
  },
  getters: {
    ccName: ({ ccName }) => ccName,
    checkInName: ({ checkInName }) => checkInName,
    checkInDistance: ({ checkInDistance }) => checkInDistance,
    payerName: ({ payerName }) => payerName,
    goCheckIn: ({ goCheckIn }) => goCheckIn,
    /**
     * @param groupCode Group Code
     * @param useFlagTrueOnly true이면 useFlag가 true인 것만 필터링하여 return. 아니면 모두 다 가져옴
     * @returns {function(*=, *=): void}
     */
    comCodes:
      (state) =>
      (groupCode, useFlagTrueOnly = true) => {
        let commonCodes = state.commonComCodesMap?.get(groupCode);
        let returnObject;
        if (!commonCodes) {
          commonCodes = state.businessComCodesMap?.get(groupCode);
        }
        if (useFlagTrueOnly) {
          commonCodes = commonCodes?.filter((commonCode) => commonCode.useFlag);
        }
        if (commonCodes) {
          returnObject = JSON.parse(
            JSON.stringify(
              commonCodes?.sort((pre, next) => pre.sortNo - next.sortNo)
            )
          );
        }
        return returnObject;
      },
    comCodeDefault:
      (state, getters) =>
      (groupCode, useFlagTrueOnly = true) => {
        const commonCodes = getters.comCodes(groupCode, useFlagTrueOnly);
        const defaultComCode = commonCodes?.find(
          (commonCode) => commonCode.defaultFlag
        );
        return defaultComCode?.comCode;
      },
    areaCodeFromDaumSidoCode: (state, getters) => (sido) => {
      const commonCodesOfAreaCode = getters.comCodes("AREA_CODE", true);
      const newHomeAddressAreaCodeObj = commonCodesOfAreaCode.find((areaCode) =>
        areaCode?.codeAbrv?.includes(sido)
      );
      return newHomeAddressAreaCodeObj?.comCode;
    },
    comCode:
      (state, getters) =>
      (groupCode, comCode, useFlagTrueOnly = true) => {
        const commonCodes = getters.comCodes(groupCode, useFlagTrueOnly);
        const filteredCommonCodesByComName = commonCodes?.filter(
          (commonCode) => commonCode.comCode === comCode
        );
        let returnCommonCode;
        if (
          Array.isArray(filteredCommonCodesByComName) &&
          filteredCommonCodesByComName.length > 0
        ) {
          returnCommonCode = filteredCommonCodesByComName[0];
        }
        return returnCommonCode;
      },
    comName:
      (state, getters) =>
      (groupCode, comCode, useFlagTrueOnly = true) => {
        return getters.comCode(groupCode, comCode, useFlagTrueOnly)?.comName;
      },
    comCodeAttrb:
      (state, getters) =>
      (groupCode, comCode, idx, useFlagTrueOnly = true) => {
        const commonCode = getters.comCode(groupCode, comCode, useFlagTrueOnly);
        const filteredCommonCodeAttrb = commonCode?.tsComDtlAttrbs?.filter(
          (comCodeAttrb) => comCodeAttrb.idx === idx
        );
        let returnCommonCodeAttrb;
        if (
          Array.isArray(filteredCommonCodeAttrb) &&
          filteredCommonCodeAttrb.length > 0
        ) {
          returnCommonCodeAttrb = filteredCommonCodeAttrb[0];
        }
        return returnCommonCodeAttrb;
      },
    comCodeAttrbName:
      (state, getters) =>
      (groupCode, comCode, idx, useFlagTrueOnly = true) => {
        const commonCodeAttrb = getters.comCodeAttrb(
          groupCode,
          comCode,
          idx,
          useFlagTrueOnly
        );
        return commonCodeAttrb?.attrbName;
      },
  },
  modules: { modal },
});
