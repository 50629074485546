<template>
  <div class="custom loader">
    <div class="custom-spinner" v-show="loading">
      <div
        :style="{
          ...spinnerStyle,
          borderColor: 'rgba(0, 0, 0, .1)',
        }"
      />
      <div
        class="custom-clip"
        :style="{
          ...spinnerStyle,
          borderColor: `${color} ${color} transparent`,
        }"
      />
    </div>
  </div>
</template>

<style scoped>
.custom-spinner {
  position: relative;
  text-align: center;
}

.custom-spinner .custom-clip {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: custom-clipDelay 0.75s 0s infinite linear;
  animation: custom-clipDelay 0.75s 0s infinite linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}

@-webkit-keyframes custom-clipDelay {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes custom-clipDelay {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<script>
export default {
  name: "CustomLoader",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#4db4ac",
    },
    size: {
      type: String,
      default: "64px",
    },
    radius: {
      type: String,
      default: "100%",
    },
  },
  computed: {
    spinnerStyle() {
      return {
        height: this.size,
        width: this.size,
        borderWidth: "5px",
        borderStyle: "solid",
        borderRadius: this.radius,
        background: "transparent",
      };
    },
  },
};
</script>
