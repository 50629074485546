export const PATH_S_FRONT = "/eco-front";

export function getEcoFrontCheckInValidation(
  reservationId,
  longitude,
  latitude
) {
  return this.http.get(`${PATH_S_FRONT}/check-in/validation`, {
    params: {
      reservationId,
      longitude,
      latitude,
    },
  });
}

export function postEcoFrontSendCertification(enc, phone) {
  return this.http.post(`${PATH_S_FRONT}/send-certification`, { enc, phone });
}

export function postEcoFrontResendCertification(enc, beforeCrtfcId) {
  return this.http.post(`${PATH_S_FRONT}/re-send-certification`, {
    enc,
    beforeCrtfcId,
  });
}
