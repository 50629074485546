import jwt_decode from "jwt-decode";

export const LOCALSTORAGE_KEY_ACCESS_TOKEN = "___act___";
export const LOCALSTORAGE_KEY_ENC = "___enc___";
export const LOCALSTORAGE_KEY_REDIRECT = "___rdt___";
export const LOCALSTORAGE_KEY_PHONE_NUMBER = "___pnb___";

export const JWT_CLAIM_GRANT_KEY = "com.fixelsoft.auth.jwt.auth.grant";
export const JWT_CLAIM_RESVE_ID_KEY = "com.fixelsoft.auth.jwt.auth.resveId";

export function saveSession(accessToken) {
  saveAccessToken(accessToken);
}

export function getAuthorizationValue() {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }
  return `S_FRONT ${accessToken}`;
}

export function removeSession() {
  removeAccessToken();
}

export function getCurrentSessionGrant() {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }
  return jwt_decode(accessToken)[JWT_CLAIM_GRANT_KEY] || null;
}

export function getCurrentSessionResveId() {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }
  return jwt_decode(accessToken)[JWT_CLAIM_RESVE_ID_KEY] || null;
}

export function getCurrentSessionSno() {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }
  return Number(jwt_decode(accessToken)["aud"]) || null;
}

function saveAccessToken(accessToken) {
  sessionStorage.setItem(LOCALSTORAGE_KEY_ACCESS_TOKEN, accessToken);
}

function getAccessToken() {
  return sessionStorage.getItem(LOCALSTORAGE_KEY_ACCESS_TOKEN);
}

function removeAccessToken() {
  localStorage.removeItem(LOCALSTORAGE_KEY_ACCESS_TOKEN);
  sessionStorage.removeItem(LOCALSTORAGE_KEY_ACCESS_TOKEN);
}

export function saveEncAndRedirectAndPhoneNumber({
  enc,
  redirect,
  phoneNumber,
}) {
  sessionStorage.setItem(LOCALSTORAGE_KEY_ENC, enc);
  sessionStorage.setItem(LOCALSTORAGE_KEY_REDIRECT, redirect);
  sessionStorage.setItem(LOCALSTORAGE_KEY_PHONE_NUMBER, phoneNumber);
}

export function getEncAndRedirectAndPhoneNumber() {
  return {
    enc: sessionStorage.getItem(LOCALSTORAGE_KEY_ENC),
    redirect: sessionStorage.getItem(LOCALSTORAGE_KEY_REDIRECT),
    phoneNumber: sessionStorage.getItem(LOCALSTORAGE_KEY_PHONE_NUMBER),
  };
}

export function removeEncAndRedirectAndPhoneNumber() {
  localStorage.removeItem(LOCALSTORAGE_KEY_ENC);
  localStorage.removeItem(LOCALSTORAGE_KEY_REDIRECT);
  localStorage.removeItem(LOCALSTORAGE_KEY_PHONE_NUMBER);
  sessionStorage.removeItem(LOCALSTORAGE_KEY_ENC);
  sessionStorage.removeItem(LOCALSTORAGE_KEY_REDIRECT);
  sessionStorage.removeItem(LOCALSTORAGE_KEY_PHONE_NUMBER);
}
