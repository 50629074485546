export const LOCALSTORAGE_KEY_COMMON_COM_CODES = "___com_com___";
export const LOCALSTORAGE_KEY_BUSINESS_COM_CODES = "___biz_com___";
export const LOCALSTORAGE_KEY_BIZ_CC_NAME = "___biz_cc_name___";
export const LOCALSTORAGE_KEY_CHECK_IN_NAME = "___check_in_name___";
export const LOCALSTORAGE_KEY_CHECK_IN_DISTANCE = "___check_in_distance___";

export function commonCodesSetCommonCodes(
  commonComCodesJson,
  businessComCodesJson
) {
  sessionStorage.setItem(
    LOCALSTORAGE_KEY_COMMON_COM_CODES,
    JSON.stringify(commonComCodesJson)
  );
  sessionStorage.setItem(
    LOCALSTORAGE_KEY_BUSINESS_COM_CODES,
    JSON.stringify(businessComCodesJson)
  );
}

export function getCommonCodesMap() {
  let commonComCodesMap;
  let businessComCodesMap;

  const commonComCodesStr = sessionStorage.getItem(
    LOCALSTORAGE_KEY_COMMON_COM_CODES
  );
  const businessComCodesStr = sessionStorage.getItem(
    LOCALSTORAGE_KEY_BUSINESS_COM_CODES
  );

  try {
    if (commonComCodesStr) {
      commonComCodesMap = new Map(
        Object.entries(JSON.parse(commonComCodesStr))
      );
    }
    if (businessComCodesStr) {
      businessComCodesMap = new Map(
        Object.entries(JSON.parse(businessComCodesStr))
      );
    }
  } catch (e) {
    commonComCodesMap = new Map();
    businessComCodesMap = new Map();
  }

  return {
    commonComCodesMap,
    businessComCodesMap,
  };
}

export function removeAlLCommonCodes() {
  sessionStorage.removeItem(LOCALSTORAGE_KEY_COMMON_COM_CODES);
  sessionStorage.removeItem(LOCALSTORAGE_KEY_BUSINESS_COM_CODES);
}

export function setCCName(name) {
  sessionStorage.setItem(LOCALSTORAGE_KEY_BIZ_CC_NAME, name);
}

export function getCCName() {
  return sessionStorage.getItem(LOCALSTORAGE_KEY_BIZ_CC_NAME);
}

export function removeCCName() {
  sessionStorage.removeItem(LOCALSTORAGE_KEY_BIZ_CC_NAME);
}

export function setCheckInName(name) {
  sessionStorage.setItem(LOCALSTORAGE_KEY_CHECK_IN_NAME, name);
}

export function getCheckInName() {
  return sessionStorage.getItem(LOCALSTORAGE_KEY_CHECK_IN_NAME);
}

export function removeCheckInName() {
  sessionStorage.removeItem(LOCALSTORAGE_KEY_CHECK_IN_NAME);
}

export function setCheckInDistance(distance) {
  sessionStorage.setItem(LOCALSTORAGE_KEY_CHECK_IN_DISTANCE, distance);
}

export function getCheckInDistance() {
  return sessionStorage.getItem(LOCALSTORAGE_KEY_CHECK_IN_DISTANCE);
}

export function removeCheckInDistance() {
  sessionStorage.removeItem(LOCALSTORAGE_KEY_CHECK_IN_DISTANCE);
}
