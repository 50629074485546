const modal = {
  namespaced: true,
  actions: {
    async confirm(context, args) {
      return new Promise(() => {
        this._vm.$EventBus.$emit("confirm", args);
      });
    },
    async closeConfirm() {
      return new Promise(() => {
        this._vm.$EventBus.$emit("closeConfirm");
      });
    },
  },
};

export default modal;
